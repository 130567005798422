import axios from "axios";
import router from "@/router";
import { Message, Loading } from "element-ui"

const instance = axios.create({
    baseURL: 'https://admin.wontonsway.com/api.php',
    timeout: 10000
})

// 请求拦截
let loadingInstance;
instance.interceptors.request.use(config => {

    // 登录相关 暂时不需要
    // let token = localStorage.getItem('token')
    // config.headers.Authorization = token

    loadingInstance = Loading.service({
        lock: true,
        text: "拼命加载中...",
        spinner: "el-icon-loading",
        background: 'rgba(0,0,0,0.7)'
    })

    return config
}, err => {
    return Promise.reject(err);
})

// 响应拦截
instance.interceptors.response.use(res => {
    loadingInstance.close();
    return res
}, err => {
    setTimeout(() => {
        loadingInstance.close();
    }, 1000);
    return Promise.reject(err)
})

export default instance
<template>
    <div class="contactus">
        <div style="font-size: 24px;text-align: center;display: flex;flex-wrap: wrap;justify-content: center;">
            <!-- <img src="../../public/img/u=2417160724,1313697090&fm=253&fmt=auto&app=138&f=JPEG.png"> -->
            <div style="font-size: 1.5rem;">我们能提供什么？</div>
            <img src="../../public/img/pc1.png">
            <div style="padding: 20px;">有意加入者，请将个人简历发至<span>{{ email }}</span>,谢谢！</div>
        </div>
        <div class="contact">
            <h1><span>欢迎联系</span></h1>
            <div style="padding-top: 30px;line-height:30px;font-size: 18px;">
                <div>联系方式：<span>{{ telephone }}</span></div>
                <div>联系邮箱：<span>{{ email }}</span></div>
                <div>联系地址：<span>{{ address }}</span></div>
            </div>

        </div>
        <div class="record" @click="link">
            <div>Copyright © 2022 万通汇网络科技有限公司 All Rights Reserved</div>
            <div>粤ICP备2022156821号</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactUs',
    data() {
        return {
            email: 'admin@wontonsway.net',
            address: '佛山市南海区广佛伟创汇911',
            telephone: '13106713888',
        }
    },
    methods: {
        link() {
            window.open('https://beian.miit.gov.cn/#/Integrated/index');
        }
    }
}
</script>

<style scoped>
.contactus {
    /* border: 1px solid red; */
    padding-top: 1.875rem;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    /* align-items: center; */
    /* justify-content: space-between; */
    align-content: space-between;
}

.contactus>div>img {
    width: 100%;
    height: 100%;
}

.contactus>div>div>span {
    color: #2DA4FF;
}

.contact {
    /* border: 1px solid red; */
    /* margin-top: 5rem; */
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.contact>h1 {
    width: 100%;
    font-weight: normal;
    text-align: center;
}

.contact>h1>span {
    padding-bottom: .625rem;
    border-bottom: 1px solid #FF6A00;
}

.record {
    text-align: center;
    width: 100%;
    padding: 1.875rem;
    cursor: pointer;

    background-color: #F2F2F2;
    /* border: 1px solid red; */
}
</style>
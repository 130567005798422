import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

function isMobile() {
    let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    return flag;
}

let isPc, routes = [];

if (isMobile()) {
    isPc = false
} else {
    isPc = true
}

if (!isPc) {
    routes = [
        {
            path: '/',
            name: 'index',
            component: () => import('@/views/h5/index.vue')
        },
        {
            path: '/news',
            name: 'news',
            component: () => import('@/views/h5/h5News.vue'),
        },
        {
            path: '/details',
            name: 'details',
            component: () => import('@/views/h5/listDetails.vue'),
        }
    ]
} else {
    routes = [
        {
            path: '/',
            name: 'newslist',
            components: {
                default: () => import('@/views/NewsList.vue'),
                anli: () => import('@/views/CaseAppreciation.vue')
            }
        },
        {
            path: '/details',
            name: 'details',
            components: {
                default: () => import('@/views/Details.vue'),
                anli: () => import('@/views/CaseAppreciation.vue')
            }
        },
        {
            path: '/caseDetails',
            name: 'caseDetails',
            components: {
                default: () => import('@/views/NewsList.vue'),
                anli: () => import('@/views/caseDetails.vue')
            }
        },
    ]
}




const router = new VueRouter({
    routes
})

export default router

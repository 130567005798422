<template>
    <div class="serve">
        <div class="title">TIKTOL全套代运营综合服务</div>
        <div class="content">
            <div v-for="(item, index) in items" :key="index">
                <div>
                    <img :src="require('../../public/img/' + item.image_url)">
                </div>
                <div>{{ item.title }}</div>
                <div style="text-align: justify;">{{ item.text }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServiceIntroduction',
    data() {
        return {
            items: [
                {
                    image_url: 'Brandequityplatform.png',
                    title: '产品运营',
                    text: '我们深入了解TikTok平台的运营规则和用户喜好，为客户量身打造高品质、富有创意的内容策划和推广方案。我们的专业团队全程负责账号管理、内容创作、数据分析和粉丝互动等环节，确保客户在TikTok平台上取得优异的成绩。',
                },
                {
                    image_url: 'Newoperation.png',
                    title: '网络搭建',
                    text: '作为一家专业的互联网公司，我们具备丰富的网络搭建经验。我们为客户提供稳定、高速的网络基础设施，保障TikTok账号的顺畅运行。针对客户的需求和业务发展，我们会不断优化和升级网络架构，满足各种复杂场景的需求。',
                },
                {
                    image_url: 'Merchantmanagement.png',
                    title: '商场搭建',
                    text: '我们为客户提供专业的电商平台建设服务，包括商城搭建、商品管理、物流配置、支付接口等一系列环节。我们结合TikTok的特性，为客户量身打造符合其品牌形象的电商平台，实现线上线下的无缝对接。',
                },
                {
                    image_url: 'Maintenanceservicecenter.png',
                    title: '技术开发',
                    text: '我们拥有一支技术实力强大的开发团队，精通TikTok开发接口，具备丰富的APP、小程序、H5等多平台开发经验。根据客户需求，我们为其打造定制化的技术解决方案，助力客户在TikTok平台实现更高效的运营和管理。',
                },
                {
                    image_url: 'sahnghuguanli.png',
                    title: '境外结算',
                    text: '我们拥有一支技术实力强大的开发团队，精通TikTok开发接口，具备丰富的APP、小程序、H5等多平台开发经验。根据客户需求，我们为其打造定制化的技术解决方案，助力客户在TikTok平台实现更高效的运营和管理。',
                },
                {
                    image_url: 'zhibofangan.png',
                    title: '直播方案',
                    text: '我们和全球知名的支付平台及银行合作，为客户打造安全稳定的境外结算环境，真实可靠的收单保证，同时确保客户的资金安全，为更多跨境电商保驾护航。',
                },
            ]
        }
    },
}
</script>

<style scoped>
.title {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
    padding-top: 60px;
}

.content {
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 800px;
    margin: 30px auto;
}

.content>div {
    text-align: center;
    width: 350px;
    /* padding: 20px; */
    /* border: 1px solid red; */
    /* margin: 20px 20px; */
}

.content>div:nth-child(1)>div:nth-child(1) {
    padding-bottom: .75rem;
}

.content>div:nth-child(2)>div:nth-child(1) {
    padding-bottom: .75rem;
}

.content>div:nth-child(2)>div:nth-child(2) {
    padding-top: 0;
}

.content>div:nth-child(4)>div:nth-child(1) {
    padding-top: 15px;
}

.content>div>div:nth-child(2) {
    font-size: 24px;
    padding: 10px 0;
}

.content>div>img {
    width: 100%;
    height: 100%;
}

.content>div:nth-child(2) {
    position: relative;
    top: -5px;
}

.content>div:nth-child(4) {
    position: relative;
    top: -5px;
}
</style>
<template>
    <div id="app" style="overflow: hidden;">
        <div v-if="isPc" :isPc="isPc" class="pc"
            :style="{ width: screenWidth + 'px', height: screenHeight + 'px', overflow: 'hidden' }">
            <div class="img" :style="{
                width: screenWidth / 2 + 'px',
                height: screenHeight + 'px',
                overflow: 'hidden',
            }">
                <img src="../public/img/pexels-flo-dahm-699459.jpg">
            </div>
            <div class="home" :style="{ height: screenHeight + 'px', overflow: 'hidden' }">
                <div class="nav">
                    <div :class="{ 'activeCss': currentPage == index }" v-for="(item, index) in listArr" :key="index"
                        @click="to(index)"><span>{{ item }}</span> </div>
                </div>
                <swiper :options="swiperOption" ref="mySwiper" id="mySwiper">
                    <swiper-slide class="swiper_slide_item">
                        <IndexPage :to="to"></IndexPage>
                    </swiper-slide>
                    <swiper-slide class="swiper_slide_item">
                        <AboutUs></AboutUs>
                    </swiper-slide>
                    <swiper-slide class="swiper_slide_item">
                        <router-view></router-view>
                    </swiper-slide>
                    <swiper-slide class="swiper_slide_item">
                        <ServiceIntroduction></ServiceIntroduction>
                    </swiper-slide>
                    <swiper-slide class="swiper_slide_item">
                        <router-view name="anli"></router-view>
                        <!-- <CaseAppreciation></CaseAppreciation> -->
                    </swiper-slide>
                    <swiper-slide class="swiper_slide_item">
                        <ContactUs></ContactUs>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <div class="h5" v-else :isPc="isPc">
            <!-- <Index></Index> -->
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import IndexPage from '@/views/IndexPage.vue'
import AboutUs from '@/views/AboutUs.vue'
import ServiceIntroduction from '@/views/ServiceIntroduction.vue'
import ContactUs from '@/views/ContactUs.vue'
import CaseAppreciation from '@/views/CaseAppreciation.vue'
import PressCenter from '@/views/PressCenter.vue'
import Index from '@/views/h5/index.vue'
import NewsList from './views/NewsList.vue'

export default {
    data() {
        return {
            isPc: '',
            isV: true,
            currentPage: 0,
            listArr: ['首页', '关于我们', '新闻中心', '服务介绍', '欣赏案例', '联系我们'],
            screenWidth: '',
            screenHeight: '',

            swiperOption: {
                mousewheel: true,
                //幻灯片放映方向
                direction: 'vertical', //通常不与左右按钮一同使用

                //幻灯片播放配置项
                // loop: false, //是否循环播放
                speed: 500, // 发生页面切换动画时，动画的切换速度
                // autoplay: {
                //     // delay: 2000, // 幻灯片停留时间
                //     disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
                //     stopOnLastSlide: true, // 切换到最后一个slide时是否停止自动切换。（loop模式下无效）。
                // },
                on: {
                    slideChangeTransitionEnd: () => {
                        this.currentPage = this.swiper.activeIndex
                    },
                }
            }
        }
    },
    components: {
        IndexPage,
        AboutUs,
        ServiceIntroduction,
        ContactUs,
        CaseAppreciation,
        PressCenter,
        Index,
        NewsList
    },
    created() {
        // this.isPc = true
        if (this.isMobile()) {
            this.isPc = false
        } else {
            this.isPc = true
        }
    },
    mounted() {
        // const that = this;
        this.screenWidth = window.screen.width;
        this.screenHeight = window.screen.clientHeight;
        // window.onresize = () => {
        //     return (() => {
        //         window.screenWidth = document.body.clientWidth;
        //         that.screenWidth = window.screenWidth;
        //     })()
        // }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        },
    },
    methods: {
        /* 导航栏切换 */
        to(index) {
            this.swiper.slideTo(index)
            this.currentPage = index
        },
        isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            );
            return flag;
        }
    },
    watch: {
        // screenWidth(val) {
        //     // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
        //     if (!this.timer) {
        //         // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        //         this.screenWidth = val
        //         this.timer = true
        //         let that = this
        //         setTimeout(function () {
        //             // 打印screenWidth变化的值
        //             that.timer = false
        //         }, 400)
        //     }
        // }
    }

}
</script>

<style scoped>
@import url(../reset.css);


/* pc */
/* @media (min-width: 1080px) { */

.img {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    left: 0;
    /* width: 50%; */
    /* height: 100vh; */
}

.img>img {
    width: 100%;
    height: 100%;
}

.home {
    /* width: 50%; */
    top: 0;
    /* height: 100vh; */
    /* flex: 1; */
    position: relative;
    right: 0;
    /* border: 1px solid red; */
    transform: translateX(25%)
}

.nav {
    position: relative;
    right: 0;
    z-index: 2;
    top: .9375rem;
    display: flex;
    transform: translateX(50%);
    width: 50%;
    background-color: #fff;
    cursor: pointer;
    /* border: 1px solid red; */
    /* height: 3.125rem; */
}

.nav>div {
    /* border: 1px solid red; */
    width: 100%;
    text-align: center;
    padding: .625rem
}

.activeCss {
    display: inline-block;
    color: #FF6300;
    background-color: #fff;

}

.activeCss>span {
    padding-bottom: 9px;
    border-bottom: 2px solid #FF6300;
}

/***************************/
/* <!--容器样式--> */
#mySwiper {
    width: 50%;
    height: 96vh;
    background-color: rgb(240, 240, 240);
    /* border: 1px solid red; */
}

/* <!--幻灯片样式--> */
.swiper-slide.swiper_slide_item {
    height: 100%;
    background-color: rgb(255, 255, 255);
}

/* <!--自定义分页器样式--> */
#mySwiper /deep/ .swiper-pagination-bullet-custom.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
}

/* } */


/* h5 */
/* @media (min-width: 768px) {} */
</style>

<template>
    <div>
        <div class="float">
            <img src="../../public/img/h5/菜单.png" style="width: 2.1875rem;" @click="drawer = true">
            <!-- <div class="btn" @click="setOut('weCoreBusiness')">111</div> -->
        </div>
        <el-drawer title="" :visible.sync="drawer" direction="ltr" :before-close="handleClose">
            <div class="drawer" style="text-align: center;">
                <div @click="setOut('shouye')">首页</div>
                <div @click="setOut('weCoreBusiness')">核心业务</div>
                <div @click="setOut('zonghefuwu')">综合服务</div>
                <div @click="setOut('tuoguananli')">托管案例</div>
                <div @click="setOut('yewuzixun')">业务咨询</div>
                <div @click="setOut('jiaruwomen')">加入我们</div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            drawer: false,
        }
    },
    created() {

    },
    methods: {
        setOut(id) {
            var element = document.getElementById(id);
            element.scrollIntoView(); // 参数 false 代表 Bottom
            this.drawer = false
        },
        handleClose(done) {
            done();
        }
    }
}
</script>

<style scoped>
.float {
    position: fixed;
    top: .625rem;
    left: .625rem;
    /* background-color: #fff; */
    width: 100%;
}

>>>.el-drawer__wrapper .ltr {
    width: 50% !important;
}

.drawer>div {
    padding: .9375rem;
    border-top: 1px solid #ececec;
}

.drawer>div:nth-last-of-type(1) {
    border-bottom: 1px solid #ececec;
}
</style>
<template>
    <div class="index">
        <img id="shouye" style="width: 100%;object-fit: cover" src="../../../public/img/h5/P-1521929-A4D71883O.png" />
        <div style="padding: 0 1.125rem;">
            <div class="title">为什么选择万通汇</div>
            <p>迎合趋势，玩转运营。因为用心，所以信任。因为专业，所以出彩。</p>
            <p style="text-align: justify;">
                广东万通汇网络科技有限公司是一家专注于TikTok全套代运营的创新型互联网公司，总部位于广东省。我们致力于为客户提供专业的TikTok运营服务，包括产品运营、网络搭建、技术开发和境外结算等。自成立以来，公司凭借优秀的服务和创新精神，赢得了广泛的客户信赖和市场口碑。
            </p>
            <img style="object-fit: cover;width: 100%;" src="../../../public/img/h5/P-1554758-B92488C3O.png">
            <div class="l1style" style="display: flex;flex-wrap: wrap;padding: 1.25rem 0;">
                <div v-for="(item, index) in l1" :key="index" style="width: 50%;text-align: center;">
                    <img :src='require("../../../public/img/h5/" + item.image + ".png")' />
                    <div>{{ item.title }}</div>
                </div>
            </div>
            <div style="width: 100%;display: flex;justify-content: space-between;margin-top: .625rem;">
                <img src="../../../public/img/h5/cb8054e4fed348ef89d49d9a1fd0aa68_2.png">
                <img src="../../../public/img/h5/ABUIABACGAAg7IO6oQYoiOnJvgQw6Qc4mQU (1).png">
            </div>
            <div id="weCoreBusiness" style="text-align: center;margin-top:4.375rem;">
                <div style="font-size: 1.75rem;font-weight: 700;color: #333333;">我们的核心业务</div>
                <div style="font-size: .875rem;margin: 1.4375rem 0;">以策略、创意、设计、技术、服务为核心的互联网公司</div>
                <div>
                    <div style="margin-top:1.875rem" v-for="(v, i) of l2" :key="i">
                        <img v-if="v.image" :src="require('../../../public/img/h5/' + v.image + '.png')">
                        <div class="product_display">
                            <div>{{ v.title }}</div>
                            <div v-for="(c, b) in v.text" :key="b">{{ c }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <img src="../../../public/img/download@2x.png" style="width: 100%;height: 100%;">
            </div>

            <infiniteScroll style="margin-top: .625rem;" :newsList="newsList"></infiniteScroll>


            <div style="margin-top: 3.125rem;">
                <div id="zonghefuwu" style="font-weight: 700;font-size: 1.5rem;text-align: center;">TikTok全套代运营综合服务</div>
                <div style="margin-top: 1.25rem;">
                    <div style="text-align: center;margin-top: .9375rem;" v-for="(v, i) in l3" :key="i">
                        <img v-if="v.img" style="width: 8rem;height: 8rem;" :src="require(`../../../public/img/${v.img}`)">
                        <div style="font-size: 1.5rem;margin: .9375rem 0;">{{ v.title }}</div>
                        <div style="text-align: justify;line-height: 1.5rem;">{{ v.conent }}</div>
                    </div>
                </div>
            </div>


            <div style="margin-top: 5rem;">
                <div id="tuoguananli"
                    style="font-size: 1.5rem;font-weight: 700;display: flex;flex-wrap: nowrap;align-items: center;justify-content: center;">
                    <div style="background-color: #FE7609;width: 3.125rem;height: .0625rem;"></div>
                    <div style="margin: 0 .625rem;">
                        <span>运营托管</span>
                        <span style="color: #FE7609;">案例</span>
                    </div>
                    <div style="background-color: #FE7609;width: 3.125rem;height: .0625rem;"></div>
                </div>

                <div style="text-align: justify;margin: 1.25rem;">
                    以运营、策划、设计、技术、服务稳为核心，为客户持续创造价值 官方后台真实数据，成功经验帮您解决后顾之忧。
                </div>

                <div style="display: flex;flex-wrap: wrap;align-items: center;justify-content: center;">
                    <div style="width: 100%;">
                        <div style="text-align: center;" v-for="(v, i) in caseList" :key="i">
                            <img style="width: 100%;" :src="v.thumb">
                            <p style="line-height: 1.25rem;">{{ v.title }}</p>
                        </div>
                    </div>

                    <el-button @click="$router.push('/news').catch(err => err)"
                        style="width: 11.5625rem;margin: 1.25rem 0;">了解更多
                        >></el-button>
                </div>

            </div>

        </div>  
        <div style="margin-bottom: 3.125rem;" id="yewuzixun">
            <img src="../../../public/img/h5/u=2417160724,1313697090&fm=253&fmt=auto&app=138&f=JPEG@2x.png"
                style="width: 100%;" alt="" />
            <p style="text-align: center;line-height: 1.875rem;padding: 0 2.5rem;">
                有意加入者，请将个人简历发至
                <a href="mailto:admin@wontonsway.net">admin@wontonsway.net</a>
                ,谢谢！
            </p>

            <div>
                <div style="text-align: center;font-size: 24px;margin: .625rem;">我们能提供什么？</div>
                <img src="../../../public/img/h5/xunwenjiage.png" alt="">
            </div>

            <div style="display: flex;justify-content: center;flex-wrap: wrap;margin-top: 1.25rem;" id="jiaruwomen">
                <div style="font-size: 1.875rem;text-align: center;width: 100%;">
                    <span style="border-bottom:.125rem solid #FF6A00;line-height: 2.5rem;">欢迎联系</span>
                </div>
                <div style="display: inline-block;color: #666666;margin-top: 1.5625rem;">
                    <p>联系方式：13106713888</p>
                    <p>联系邮箱：<a href="mailto:admin@wontonsway.net">admin@wontonsway.net</a></p>
                    <p>联系地址：佛山市南海区广佛伟创汇911</p>
                </div>
            </div>
        </div>
        <div style="text-align: center;margin: .625rem;" @click="wOpen">
            粤ICP备2022156821号-1
        </div>
        <floatBtn></floatBtn>
    </div>
</template>

<script>
import api from '@/util/api'
import infiniteScroll from '@/components/infiniteScroll.vue'
import floatBtn from '@/components/floatBtn.vue'

export default {
    name: 'index',
    components: {
        infiniteScroll,
        floatBtn
    },
    data() {
        return {
            l1: [
                {
                    title: '专业的产品运营',
                    image: 'ap(2)'
                },
                {
                    title: '高效的网络搭建',
                    image: 'h5(2)'
                },
                {
                    title: '先进的技术开发',
                    image: 'fuwug(2)'
                },
                {
                    title: '专业的商场搭建',
                    image: 'data'
                },
                {
                    title: '成熟的直播方案',
                    image: 'zhibo'
                },
                {
                    title: '快捷的支付途径',
                    image: 'zhifu'
                },
            ],
            l2: [
                {
                    title: 'TikTok产品运营',
                    image: '201703241543530af022',
                    text: ['为客户量身打造内容', '方案专业团队管理', '内容创作、数据分析']
                },
                {
                    title: 'tiktok直播解决方案',
                    image: 'zb2',
                    text: ['量身打造直播流程', '量身定制直播间', '资深主播带货培训']
                },
                {
                    title: '商场搭建',
                    image: '20170324154353fca4b6',
                    text: ['专业的电商平台建设', '品牌形象创意设计', '线上线下的无缝对接']
                },
                {
                    title: '支付收单',
                    image: '支付',
                    text: ['成熟稳定的首款渠道', '快捷便利的支付方式', '安全可靠的收单通道', '24小时在线服务']
                },
                {
                    title: '技术开发',
                    image: '20170324154353f2303d',
                    text: ['精通TikTok开发接口', '多平台开发经验', '高效的运营和管理']
                }
            ],
            l3: [
                {
                    img: '品牌资产平台@2x.png',
                    title: '产品运营',
                    conent: '我们深入了解TikTok平台的运营规则和用户喜好，为客户量身打造高品质、富有创意的内容策划和推广方案。我们的专业团队全程负责账号管理、内容创作、数据分析和粉丝互动等环节，确保客户在TikTok平台上取得优异的成绩。',
                },
                {
                    img: '新运营@2x.png',
                    title: '网络搭建',
                    conent: '作为一家专业的互联网公司，我们具备丰富的网络搭建经验。我们为客户提供稳定、高速的网络基础设施，保障TikTok账号的顺畅运行。针对客户的需求和业务发展，我们会不断优化和升级网络架构，满足各种复杂场景的需求。',
                },
                {
                    img: '商户管理@2x.png',
                    title: '商场搭建',
                    conent: '我们为客户提供专业的电商平台建设服务，包括商城搭建、商品管理、物流配置、支付接口等一系列环节。我们结合TikTok的特性，为客户量身打造符合其品牌形象的电商平台，实现线上线下的无缝对接。',
                },
                {
                    img: 'zhibofangan.png',
                    title: '直播方案',
                    conent: '由资深tiktok直播团队和mcn机构，为客户量身打造适合其产品宣传推广的直播流程，并根据数据优化直播转化，结合自身的电商资源为客户提供适合的转化途径，全方位解决tiktok直播的系统难题。',
                },
                {
                    img: 'sahnghuguanli.png',
                    title: '境外结算',
                    conent: '我们和全球知名的支付平台及银行合作，为客户打造安全稳定的境外结算环境，真实可靠的收单保证，同时确保客户的资金安全，为更多跨境电商保驾护航。',
                },
                {
                    img: '维修服务中心@2x.png',
                    title: '技术开发',
                    conent: '我们拥有一支技术实力强大的开发团队，精通TikTok开发接口，具备丰富的APP、小程序、H5等多平台开发经验。根据客户需求，我们为其打造定制化的技术解决方案，助力客户在TikTok平台实现更高效的运营和管理。',
                },
            ],
            newsList: [],
            caseList: [],
        }
    },
    mounted() {
        this.getNewsList()
        this.getCaseList()
    },
    methods: {
        getNewsList() {
            let data = {
                lang: 'zh-cn',
                page: 1,
                rows: 100
            }
            api.getNewsList(data).catch(err => {
                // 提示错误, 终止执行
                return console.error(err)
            }).then(res => {
                let data = JSON.parse(res.data);
                // console.log(data);
                if (data.msg == "success") {
                    this.newsList = data.data
                }
            })
        },
        getCaseList() {
            let data = {
                lang: 'zh-cn',
                page: 1,
                rows: 4,
            }
            api.getCaseList(data).then(res => {
                let d = JSON.parse(res.data)
                if (d.code >= 0) {
                    this.caseList = d.data
                    console.log(d);
                }

            })
        },
        wOpen() {
            window.open('https://beian.miit.gov.cn/')
        }
    }
}
</script>

<style scoped>
.index .title {
    font-weight: 700;
    font-size: 1.875rem;
    margin-top: 1rem;
}

.index .l1style>div {
    margin-bottom: .625rem;
}

.product_display>div {
    margin: 1.25rem;
}

a {
    text-decoration: none;
    color: rgb(103, 194, 236);
}
</style>
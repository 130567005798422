<template>
    <div class="case">
        <div class="text">
            <h1>运营托管案例</h1>
            <p>以运营、策划、设计、技术、服务稳为核心，为客户持续创造价值</p>
            <p>官方后台真实数据，成功经验帮您解决后顾之忧。</p>
        </div>
        <div :class="'example'">
            <div v-for="(item, index) in examples" :key="index" style="text-align: center;" @click="goToDetails(item.id)">
                <div class="case_image">
                    <el-image style="width:180px; height: 160px;" :src="item.thumb"></el-image>
                </div>
                <div class="text2">{{ item.title }}</div>
                <!-- <div class="text2">{{ item.description }}</div> -->
            </div>
        </div>
        <div style="text-align: center;">
            <el-pagination background layout="prev, pager, next" :total="total"
                @current-change="currentChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import api from '@/util/api';

export default {
    name: 'CaseAppreciation',
    data() {
        return {
            srcList: [],
            examples: [],
            total: 1,
            currentPage: 1
        }
    },
    created() {
        this.getCaseList();
    },
    methods: {
        getCaseList() {
            let data = {
                lang: 'zh-cn',
                page: this.currentPage,
                rows: 9,
            }
            api.getCaseList(data).then(res => {
                let d = JSON.parse(res.data)
                if (d.code == 1) {
                    this.examples = d.data
                    this.total = d.total
                }

            })
        },
        currentChange(page) {
            this.currentPage = page;
            this.getCaseList()
        },
        goToDetails(id) {
            this.$router.push('/caseDetails?id=' + id);
        },
    },
}
</script>

<style scopde>
.case {
    /* padding: 0 100px; */
    width: 780px;
    height: 900px;
    margin: 0 auto;
    /* border: 1px solid red; */
}

h1 {
    font-weight: normal;
    font-size: 36px;
}

.text {
    text-align: center;
}

.example2 {
    /* margin-top: 30px; */
    display: inline-block;
}

.example {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 30px;
}

.example>div {
    width: 200px;
    height: 200px;
    padding-bottom: 10px;
    margin-bottom: 20px;
    text-align: center;
    /* border: 1px solid red; */
}

.case_image>img {
    width: 100%;
    height: 100%;
}

.text2 {
    width: 200px;
    font-size: 14px;
    text-align: center;
}
</style>
<template>
    <div class="about">
        <div>
            <img src="../../public/img/20170327151259b9db1b.png">
        </div>
        <div class="font1">
            <div>我们的核心业务</div>
            <div>以策略、创意、设计、技术、服务为核心的互联网公司</div>
            <div></div>
        </div>
        <div class="icon">
            <div v-for="(item, index) in icon_img" :key="index">
                <div>
                    <img :src="require('../../public/img/' + item.url)">
                </div>
                <div>{{ item.title }}</div>
                <div>{{ item.text1 }}</div>
                <div>{{ item.text2 }}</div>
                <div>{{ item.text3 }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutUs',
    data() {
        return {
            icon_img: [
                {
                    url: '201703241543530af022.png',
                    title: 'TikTok产品运营',
                    text1: '为客户量身打造内容、方案',
                    text2: '专业团队管理',
                    text3: '内容创作、数据分析',
                },
                {
                    url: '20170324154353fca4b6.png',
                    title: '商场搭建',
                    text1: '专业的电商平台建设',
                    text2: '品牌形象创意设计',
                    text3: '线上线下的无缝对接',
                },
                {
                    url: '20170324154353f2303d.png',
                    title: '技术开发',
                    text1: '精通TikTok开发接口',
                    text2: '多平台开发经验',
                    text3: '高效的运营和管理',
                },
                {
                    url: '20170324154353f2303d.png',
                    title: '支付收单',
                    text1: '成熟稳定的首款渠道',
                    text2: '快捷便利的支付方式',
                    text3: '安全可靠的收单通道',
                },
                {
                    url: '20170324154353f2303d.png',
                    title: 'tiktok直播解决方案',
                    text1: '量身打造直播流程',
                    text2: '量身定制直播间',
                    text3: '资深主播带货培训',
                }
            ]
        }
    }

}
</script>

<style scoped>
.about {
    padding-top: 10px;
}

.font1 {
    text-align: center;
    padding: 50px 0 0 0;
}

.font1>div:nth-child(1) {
    font-size: 24px;
    padding-bottom: 14px;
}

.font1>div:nth-child(2) {
    color: #666666;
}

.font1>div:nth-child(3) {
    margin: 0 auto;
    margin-top: 50px;
    border-bottom: 1px solid #e0e0e0;
    width: 70%;
}

.icon {
    display: flex;
    padding: 0 2.5rem;
    padding-top: 71px;
    justify-content: space-between;
}

.icon>div:nth-child(1)>div:nth-child(1) {
    margin-bottom: 5px;
}

.icon>div>div {
    text-align: center;
    padding-bottom: 20px;
    /* border: 1px solid red; */
}

.icon>div>div:nth-child(2) {
    /* border: 1px solid red; */
    padding-top: 32px;
    font-weight: 700;
    font-size: 24px;
}
</style>
<template>
    <div class="news">

        <h2>新闻动态</h2>
        <div class="card">
            <div v-for="(item, index) in news_data.top" :key="index" @click="toNewList">
                <div class="image">
                    <img :src="require('../../public/img/' + item.image_url)">
                </div>
                <div>{{ item.content }}</div>
            </div>
        </div>
        <div class="card2">
            <div v-for="(item, index) in news_data.bottom" :key="index">
                <div>
                    <el-image style="width: 100px; height: 100px" :src="require('../../public/img/' + item.image_url)"
                        :preview-src-list="srcList">
                    </el-image>
                </div>
                <div class="text">
                    <div>{{ item.title }}</div>
                    <div>{{ item.content }}</div>
                    <div class="time">{{ item.time }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PressCenter',
    data() {
        return {
            srcList: ['../../public/img/201703241543530af022.png'],
            news_data: {
                top: [
                    {
                        image_url: '矩形1.png',
                        content: '此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述',
                    },
                    {
                        image_url: '矩形1.png',
                        content: '此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述',
                    },
                    {
                        image_url: '矩形1.png',
                        content: '此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述',
                    },
                    {
                        image_url: '矩形1.png',
                        content: '此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述此处为文字描述',
                    },
                ],
                bottom: [
                    {
                        image_url: '矩形3.png',
                        title: '包括设计师和摄影师，都使用一种称为三分法的策略',
                        content: '许多人，包括设计师和摄影师，都使用一种称为三分法的策略。这将您的工作区想象成一个3x3 的网格。',
                        time: '2023-05-05',
                    },
                    {
                        image_url: '矩形3.png',
                        title: '包括设计师和摄影师，都使用一种称为三分法的策略',
                        content: '许多人，包括设计师和摄影师，都使用一种称为三分法的策略。这将您的工作区想象成一个3x3 的网格。',
                        time: '2023-05-05',
                    },
                ]
            }
        }
    },
    methods: {
        toNewList() {
            this.$router.push('/newslist');
        }
    }
}
</script>

<style scopde>
.news {
    height: 95vh;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

h2 {
    /* border: 1px solid red; */
    width: 670px;
    font-size: 30px;
    line-height: 30px;
    height: 30px;
}

.card {
    width: 670px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* border: 1px solid red; */
}

.card>div {
    width: 400px;
    /* border: 1px solid red; */
    margin-bottom: 10px;
}

.card>div:nth-child(2),
.card>div:nth-child(3) {
    width: 260px;
}

.card>div>.image {
    height: 200px;
    width: 100%;
}

.card>div>.image>img {
    width: 100%;
    height: 100%;
}

/* .card2 {
    border: 1px solid red;
    height: 16.875rem;
} */

.card2>div:nth-child(2) {
    padding: 10px 0;
}

.card2>div {
    display: flex;
    width: 670px;
}

.card2>div>div>img {
    width: 100%;
    height: 100%;
}

.card2>div>div:nth-child(1) {
    margin-right: 10px;
    height: 122px;
    width: 166px;
}

.card2>div:nth-child(1) {
    border-bottom: 1px solid #848484;
}

.card2>div>div>div {
    text-align: left;
    /* border: 1px solid red; */
}

.text>div:nth-child(1) {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 10px
}

.text>div:nth-child(2) {
    line-height: 25px;
}

.time {
    /* display: inline-block; */
    /* position: relative; */
    text-align: right !important;
    margin-top: 25px;
    font-size: 12px;
    /* border: 1px solid red; */
}
</style>
<template>
    <div class="index">
        <!-- 文字介绍 -->
        <div class="first-line">
            <div class="first-text">
                <div>
                    <div style="font-size:32px;font-weight: 700;">{{ h1 }}</div>
                    <p style="font-size: 15px;">{{ p1 }}</p>
                </div>
                <div class="but" @click="to(4)">
                    点击查看
                    <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-up"></use>
                    </svg>
                </div>
                <p style="line-height: 25px;display: inline-block;text-align: justify;">{{ p2 }}</p>
            </div>
            <div class="image">
                <img src="../../public/img/P-1554758-B92488C3O.png">
            </div>
        </div>
        <!-- 中间三个图标 -->
        <div class="iocn">
            <div v-for="(item, index) in iocn_url" :key="index">
                <div class="iocn_image" v-if="item.url">
                    <img :src="require('../../public/img/' + item.url)" />
                </div>
                <span>{{ item.text }}</span>
            </div>
        </div>
        <!-- 轮播图 -->
        <div class="swiper">
            <swiper :options="swiperOption" ref="mySwiper" id="mySwiper">
                <swiper-slide class="swiper_slide_item" v-for="(i, index) in examples" :key="index" @click="to(4)">
                    <div class="swiper_image">
                        <img :src="i.thumb">
                    </div>
                    <!-- <el-image style="width:230px; height: 210px;border-radius: 3px;" :src="i.thumb"></el-image> -->
                    <div>{{ i.title }}</div>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<script>
import '../../public/js/iconfont';
import api from '@/util/api';

export default {
    name: 'IndexPage',
    props: ['to'],
    data() {
        return {
            examples: '',
            h1: '为什么选择万通汇',
            p1: '迎合趋势，玩转运营，因为用心，所以信任，因为专业，所以出彩。',
            p2: '广东万通汇网络科技有限公司是一家专注于TikTok全套代运营的创新型互联网公司，总部位于广东省。我们致力于为客户提供专业的TikTok运营服务，包括产品运营、网络搭建、技术开发和境外结算等。自成立以来，公司凭借优秀的服务和创新精神，赢得了广泛的客户信赖和市场口碑。',

            iocn_url: [
                {
                    url: 'ap.png',
                    text: '专业的产品运营'
                },
                {
                    url: 'h5.png',
                    text: '高效的网络搭建'
                },
                {
                    url: 'fuwug.png',
                    text: '先进的技术开发'
                },
                {
                    url: 'data.png',
                    text: '专业的商场搭建'
                },
                {
                    url: 'zb.png',
                    text: '成熟的直播方案'
                },
                {
                    url: 'jy.png',
                    text: '快捷的支付途径'
                },
            ],

            //swiperOption：swiper配置项信息，需要绑定在swiper标签的 :option 属性中
            swiperOption: {

                //幻灯片放映方向
                // direction: 'vertical', //通常不与左右按钮一同使用

                slidesPerView: 3,
                spaceBetween: 20,


                //幻灯片播放配置项
                loop: true, //是否循环播放
                speed: 1000, // 发生页面切换动画时，动画的切换速度
                autoplay: {
                    delay: 3000, // 幻灯片停留时间
                    disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay
                    stopOnLastSlide: true, // 切换到最后一个slide时是否停止自动切换。（loop模式下无效）。
                },
                // on: {
                //     slideChangeTransitionEnd: function () {
                //         console.log(this.activeIndex); //每次切换结束时，在控制台打印现在是第几个slide
                //     },
                // },

            },
        }
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        },
    },
    mounted() {
        this.getCaseList()
    },
    methods: {
        toServiceIntroduction() {
            this.$emit('toServiceIntroduction', 2)
        },
        getCaseList() {
            let data = {
                lang: 'zh-cn',
                page: 1,
                rows: 3,
            }
            api.getCaseList(data).then(res => {
                let d = JSON.parse(res.data)
                if (d.code == 1) {
                    this.examples = d.data
                }
            })
        }
    },
}
</script>

<style scoped>
.icon {
    width: 1em;
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    color: #FF7200;
    font-weight: 700;
}

.but {
    cursor: pointer;
    border: 2px solid #FD6F28;
    padding: 12px 15px;
    border-radius: 10px;
    color: #FD6F28;
}

.index {
    padding: 20px 100px;
    padding-top: 30px;
    height: 90vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow: hidden;
}

.first-line {
    display: flex;
    height: 22.625rem;
    width: 50rem;
}

.first-text {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    align-content: space-between;
}

.image {
    margin-left: 40px;
    width: 2000px;
}

.image>img {
    width: 100%;
    height: 100%;
}

.iocn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    /* padding: 1.875rem 0; */
}

.iocn_image>img {
    width: 100%;
    height: 100%;
}

.iocn_image {
    height: 140px;
}

.iocn>div>span {
    /* padding-top: .625rem; */
    display: block;
    text-align: center;
}

/*容器样式 */
#mySwiper {
    width: 100%;
    height: 240px;
    background-color: #fff;
    /* border: 1px solid red; */
}

/*幻灯片样式*/
.swiper-slide.swiper_slide_item {
    /* height: 300px; */
    width: 100%;
    height: 100%;
    /* border: 1px solid red; */
    /* background-color: aliceblue; */
}

/*自定义分页器样式*/
/* #mySwiper /deep/ .swiper-pagination-bullet-custom.swiper-pagination-bullet {
    width: .9375rem !important;
    height: .9375rem !important;
} */

.swiper_image {
    border-radius: 3px;
    height: 220px;
    overflow: hidden;
}

.swiper_image>img {
    width: 100%;
    height: 100%;
}

.swiper_slide_item>div {
    text-align: center;
}

.swiper {
    width: 100%;
    height: 230px;
    position: relative;
    bottom: 20px;
    top: 10px;
    /* overflow: hidden; */
}
</style>
<template>
    <div>
        <div class="title">新闻动态</div>
        <div class="infinite-list-wrapper" style="overflow:auto">
            <div class="list" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
                <div class="list_item" v-for="(v, i) in dataList" :key="i" v-if="v" @click="toDetails(v)">
                    <p>{{ v.title }}</p>
                    <span class="time">{{ v.create_time }}</span>
                </div>
            </div>
            <p v-if="loading">加载中...</p>
            <p v-if="noMore">没有更多了</p>
        </div>
    </div>
</template>
  
<script>
export default {
    props: {
        newsList: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            count: 5,
            loading: false,
            dataList: []
        }
    },
    computed: {
        noMore() {
            return this.count >= this.newsList.length
        },
        disabled() {
            return this.loading || this.noMore
        },

    },
    created() {
        for (let i = 0; i < 5; i++) {
            this.dataList.push(this.newsList[i])
        }
    },
    methods: {
        load() {
            this.loading = true
            setTimeout(() => {
                this.count += 5
                this.dataList = [];
                for (let i = 0; i < this.count; i++) {
                    this.dataList.push(this.newsList[i])
                }
                this.loading = false
            }, 1000)
        },
        toDetails(item) {
            this.$router.push('/details?id=' + item.id + '&type=news').catch(err => err);
        }
    }
}
</script>

<style scoped>
.title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.5rem;
}

.infinite-list-wrapper {
    height: 11.25rem;
    /* border: 1px solid red; */
}

.infinite-list-wrapper>p {
    text-align: center;
    color: #dbdada;
}

.list>.list_item>p {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #464646;
    /* border: 1px solid red; */
}

.list_item {
    margin: .9375rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    /* border: 1px solid red; */
    height: 1.25rem;
}

.list_item :nth-of-type(1) {
    margin: 0;
}

.list_item>.time {
    height: .875rem;
    overflow: hidden;
    font-size: .875rem;
    /* width: 60%; */
    transform: translateY(0);
    /* border: 1px solid red; */
}
</style>
<template>
    <div class="new">
        <div class="new_image">
            <img src="../../public/img/u=1680398913,603915006&fm=253&fmt=auto&app=138&f=JPEG.png" alt="">
        </div>
        <div class="new_nav">
            <div>您的位置：</div>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>新闻中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/' }">新闻列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="newlist">
            <div v-for="(item, index) in datalist" :key="index" @click="goToDetails(item.id)">
                <div style="margin-right: 20px;">
                    <el-image style="width:160px; height: 140px;" :src="item.thumb"></el-image>
                </div>
                <div class="new_text">
                    <div>{{ item.title }}</div>
                    <div>{{ item.description }}</div>
                    <div>
                        <span>{{ item.create_time }}</span>
                        <span>了解详情</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="paging">
            <el-pagination background layout="prev, pager, next" :total="total"
                @current-change="currentChange"></el-pagination>
        </div>
    </div>
</template>

<script>
import api from '@/util/api';

export default {
    name: 'NewsList',
    data() {
        return {
            datalist: [],
            total: 0,
            currentPage: 1,
        }
    },
    mounted() {
        this.getNewsList()
    },
    methods: {
        goToDetails(id) {
            this.$router.push('/details?id=' + id + '&type=new');
        },
        getNewsList() {
            let data = {
                lang: 'zh-cn',
                page: this.currentPage,
            }
            api.getNewsList(data).catch(err => {
                return console.error(err)
            }).then(res => {
                let data = JSON.parse(res.data);
                if (data.code == 1) {
                    this.datalist = data.data;
                    this.total = data.total
                }
            })
        },
        currentChange(page) {
            this.currentPage = page;
            this.getNewsList()
        },
    }
}
</script>

<style scopde>
.new {
    margin-top: 10px;
    /* border: 1px solid red; */
}

.new_image>img,
.new_image2>img {
    width: 100%;
    height: 100%;
    position: relative;
}

.new_image {
    height: 240px;
    width: 100%;
}

.new_nav {
    display: flex;
    align-items: center;
    width: 670px;
    margin: 20px 120px; 
    border-left: 10px solid #FF6A00;
    padding-left: 5px;
}

.new_image2 {
    height: 140px;
    border: 1px solid red;
    width: 280px;
    margin-right: 20px;
    overflow: hidden;
}

.newlist {
    margin: 0 120px;
}

.newlist>div {
    display: flex;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #CCCCCC;
}


.new_text {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.new_text>div {
    /* border: 1px solid red; */
    width: 100%;
}

.new_text>div:nth-child(1) {
    font-size: 24px;
}

.new_text>div:nth-child(3) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    /* height: 14px; */
    /* border: 1px solid red; */
}

.new_text>div:nth-child(3)>span:nth-child(2) {
    border: 1px solid #FF6A00;
    padding: 5px;
    color: #FF6A00;
    cursor: pointer;
}

.newlist>div:hover {
    border-color: #FF6A00;
    color: #FF6A00;
}

.paging {
    text-align: center;
    display: flex;
    justify-content: center;
    margin: 0 120px;
    margin-top: 30px;
    /* border: 1px solid red; */
    /* width: 600px; */
}
</style>